import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { MatxLoading } from "app/components";
import Cookies from "js-cookie";
import igtAxios from "app/auth/axios-custom";

const TOKEN_KEY = "authToken";

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
};

const isValidToken = (token) => {
  if (!token) return false;
  
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
  } catch (exceptionVar) {
    return false;
  }
  
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem(TOKEN_KEY, token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem(TOKEN_KEY);
    delete axios.defaults.headers.common.Authorization;
  }
};

const fetchProfile = async (token, url) => {
  try {
    const response = await igtAxios.get(`${url}/users/profile`);
    return response.data.user;
  } catch (error) {
    console.error("Profile fetch error:", error);
    throw error;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => {},
  updateProfile: () => {},
  setState: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const backendURL = process.env.REACT_APP_BACKEND_URI;
  const token = localStorage.getItem(TOKEN_KEY);

  const setUser = (user) => {
    setState({
      ...state,
      user,
    });
  };


  const logout = () => {
    setSession(null);
    setState({
      ...state,
      isAuthenticated: false,
      user: null,
    });
    Cookies.remove("authToken");
  };

  const updateProfile = async () => {
    const user = await fetchProfile(token, backendURL);
    setState({
      ...state,
      isAuthenticated: true,
      user,
      isInitialised: true,
    });
    return user;
  };

  useEffect(() => {
    const initialize = async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      const cookieToken = Cookies.get("authToken");

      if (token && isValidToken(token) && cookieToken) {
        try {
          await updateProfile();
        } catch (error) {
          setState({
            ...state,
            isAuthenticated: false,
            user: null,
            isInitialised: true,
          });
        }
      } else {
        setState({
          ...state,
          isAuthenticated: false,
          user: null,
          isInitialised: true,
        });
      }
    };

    initialize();
  }, [backendURL, token]);

  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        logout,
        updateProfile,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
