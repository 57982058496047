import { lazy } from 'react';

export const MatxLayouts = { layout1: lazy(() => import('./MatxLayout/Layout1/Layout1')) };
export const MatxTheme = lazy(() => import('./MatxTheme/MatxTheme'));
export const MatxLogo = lazy(() => import('./MatxLogo'));
export const MatxSuspense = lazy(() => import('./MatxSuspense'));
export const MatxMenu = lazy(() => import('./MatxMenu'));
export const MatxLoading = lazy(() => import('./MatxLoading'));
export const MatxVerticalNav = lazy(() => import('./MatxVerticalNav/MatxVerticalNav'));
export const SimpleCard = lazy(() => import('./SimpleCard'));
export const Breadcrumb = lazy(() => import('./Breadcrumb'));
export const MDBox = lazy(() => import('./MDBox/MDBoxRoot'));
export const MDTypography = lazy(() => import('./MDTypography/MDTypographyRoot'));