import { CssBaseline } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { PaymentWrapper } from "./components/PaymentWrapper";
import routes from "./routes";
import "../fake-db";
import '../tailwind.css';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        <PaymentWrapper>
          <MatxTheme>
            <ToastContainer position="bottom-right" />
            <CssBaseline />
            {content}
          </MatxTheme>
        </PaymentWrapper>
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
