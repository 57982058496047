import React, { createContext, useContext, useState } from "react";
import useAuth from "app/hooks/useAuth";
import igtAxios from "app/auth/axios-custom";

const SelectedItemContext = createContext();

export const useSelectedItem = () => useContext(SelectedItemContext);

export const SelectedItemProvider = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [inventoryItems, setInventoryItems] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URI;

  const { user } = useAuth();

  const fetchInventoryItems = async () => {
    try {
      const response = await igtAxios.get(
        `${backendURL}/inventory?userId=${user?.id}`
      );
      const createInventoryItems = response.data.map((item) => {
        return {
          ...item,
          category: item.Categories?.name || "",
          collection: item.Collections?.name || "",
        }
      })
      setInventoryItems(createInventoryItems);
    } catch (error) {
      console.error("Failed to fetch inventory items", error);
    }
  };

  return (
    <SelectedItemContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        inventoryItems,
        setInventoryItems,
        fetchInventoryItems,
      }}
    >
      {children}
    </SelectedItemContext.Provider>
  );
};